import React from "react";
import Router from "next/router";

const Home = ({ host, env }) => {
  React.useEffect(() => {
    const { pathname } = Router;
    if (pathname == "/") {
      // Update this to the default route when
      window.location.href = "http://lodge804.com/#!/alumni";
    }
  }, []);
  return null;
};

export default Home;
